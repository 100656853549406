import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class Footer extends Component {
  render() {
    return (<footer className="page-footer">
      <div>
        <Link to="/imprint">Imprint</Link>
      </div>
    </footer>);
  }
}
