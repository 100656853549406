const projectsData = {
  data: [
    {
      image_1: 'parity.png',
      title: 'Parity Technologies GmbH',
      date: '2018-2021',
      project_url: 'http://parity.io',
      description: 'In late 2018 I joined Parity Technologies as a full-time Software Engineer JavaScript to support the team in building their decentralized network protocol <a href="https://polkadot.network/" target="_blank" title="Go to website">Polkadot</a> and to work on a variety of tasks in the development process of the Blockchain Framework <a href="https://substrate.io/" target="_blank" title="Go to website">Substrate</a>.',
      tagline: [
        'JavaScript',
        'TypeScript',
        'Node.js',
        'React',
        'RxJS',
        'Jest',
        'yarn',
        'WebAssembly',
        'AssemblyScript',
        'JSON',
        'Docker',
        'Docker Compose',
        'Yaml',
        'GitLab CI',
        'Github Actions',
        'npm',
        'Electron',
        'wasm-pack',
        'Cargo',
        '(Rust)'
      ]
    },
    {
      image_1: 'planet-labs.png',
      title: 'Planet Labs',
      date: '2017/2018',
      client: 'Planet Labs Germany GmbH',
      project_url: 'http://planet.com',
      description: 'I was hired as a Senior Frontend Developer to support the Software Engineering team in building administrative and analytical geospatial Web Applications for Planet Labs.',
      tagline: [
        'React',
        'Redux',
        'Jest',
        'd3.js',
        'Crossfilter',
        'OpenLayers',
        'Leaflet',
        'Webpack',
        'Babel',
        'yarn',
        'ES6',
        'SASS',
        'create-react-app',
        'Material UI',
        'Docker'
      ]
    }, {
      image_1: 'curvedimage-calculator.png',
      image_2: '',
      title: 'Curvedimge Calculator',
      date: '2017',
      project_url: 'https://stefie.github.io/aframe-curvedimage-calculator/',
      github_url: 'https://github.com/Stefie/aframe-curvedimage-calculator',
      description: 'A helper app for A-Frame\'s a-curvedimage component. It helps you to safely adjust the geometry attributes based on the image ratio of the asset you\'re planning to use without distorting the image.',
      tagline: [
        'React',
        'A-Frame VR',
        'A-Frame React',
        'WebVR',
        'JSX',
        'Webpack',
        'Babel',
        'npm',
        'ES6',
        'Stylus'
      ]
    }, {
      image_1: 'wikipedia.png',
      title: 'Wikipedia WebVR Recent Changes Heatmap',
      date: '2017',
      github_url: 'https://github.com/Stefie/vr-wikipedia-heatmap',
      project_url: 'http://vr-wikipedia-heatmap.stefaniedoll.de/',
      description: 'This app shows you the  150 most recent changes to Wikipedia entries as a livestream on a WebVR-Globe. It uses the Wikipedia Stream-API.',
      tagline: [
        'React',
        'A-Frame VR',
        'A-Frame React',
        'WebVR',
        'JSX',
        'Babel',
        'Webpack',
        'npm',
        'ES6',
        'Stylus'
      ]
    }, {
      image_1: 'goodschool.png',
      image_2: 'goodschool-drawing.png',
      title: 'Graphic Recording in WebVR',
      date: '2017',
      client: 'Goodschool Hamburg',
      project_url: 'http://www.good-school.de/virtual-reality/',
      description: 'An analog Graphic Recording of a Workshop day  at <a href="http://good-school.de" target="_blank" title="Go to website">Good School Hamburg</a>  - put together in WebVR. In cooperation with <a href="http://annalenaschiller.com/" target="_blank" title="Go to website">Anna-Lena Schiller</a>.',
      tagline: ['A-Frame VR', 'Gulp', 'Pug', 'Stylus']
    }, {
      image_1: 'augsburger-allgemeine.png',
      image_2: '',
      title: 'Augsburger Allgemeine Mobile Website',
      date: '2016/2017',
      client: 'prepublic GmbH',
      project_url: 'http://www.augsburger-allgemeine.de/?mobile_relaunch=true',
      description: 'I built the frontend for the new mobile website of the daily newspaper Augsburger Allgemeine Zeitung.',
      tagline: [
        'SCSS',
        'Gulp',
        'Mustache',
        'JavaScript',
        'Ajax',
        'JSON'
      ]
    }, {
      image_1: 'ibb.png',
      title: 'IBB Frontend Relaunch',
      date: '2016',
      client: 'interactive tools GmbH',
      project_url: 'https://www.ibb.de/',
      description: 'I was part of the core team during the frontend relaunch  of the responsive website for the financial institution Investitionsbank Berlin.',
      tagline: [
        'SCSS',
        'HTML',
        'YouTube JavaScript API',
        'JavaScript',
        'jQuery',
        'Grunt',
        'Bootstrap'
      ]
    }, {
      image_1: 'gkv-emag.png',
      title: 'GKV E-Magazine',
      date: '2016',
      client: 'interactive tools GmbH',
      project_url: 'https://www.gkv-90prozent.de/',
      description: 'I worked on the article filters, dynamic content loading and mobile optimization for the E-Magazine of the National Association of Statutory Health Insurance Funds.',
      tagline: [
        'LESS',
        'HTML',
        'Grunt',
        'Bootstrap',
        'JavaScript',
        'JSON'
      ]
    }, {
      image_1: 'gkv-clinic.png',
      title: 'GKV Klinik-Simulator',
      date: '2016',
      client: 'interactive tools GmbH',
      project_url: 'https://www.gkv-kliniksimulator.de/',
      description: 'I built the Google Maps integration for a clinic search app for the National Association of Statutory Health Insurance Funds',
      tagline: ['Google Maps JavaScript API', 'JavaScript']
    }, {
      image_1: 'is24.png',
      title: 'ImmobilienScout24 AT Search Frontend',
      date: '2015',
      client: 'Scout24 GmbH',
      project_url: 'https://www.immobilienscout24.at/',
      description: 'I worked closely with the UX-Team while building the templates for the search app of the austrian property search platform.',
      tagline: [
        'Gulp',
        'JADE',
        'Stylus',
        'Jeet',
        'YouTube JavaScript API',
        'JavaScript',
        'JSON'
      ]
    }, {
      image_1: 'is24-cms.png',
      title: 'ImmobilienScout24 AT CMS Frontend',
      date: '2015',
      client: 'Scout24 GmbH',
      project_url: 'https://www.immobilienscout24.at/ratgeber/',
      description: 'I did the frontend development for the editorial CMS content of the austrian platform.',
      tagline: [
        'JADE',
        'Stylus',
        'Jeet',
        'Gulp',
        'JavaScript',
        'JSON'
      ]
    }, {
      image_1: 'audi.png',
      title: 'AUDI Approved Used Car Locator',
      date: '2015',
      client: 'Razorfish Berlin',
      project_url: 'http://www.audi-boerse.de/gebrauchtwagen/url-2_1-list.htm',
      description: 'I built the filters and the compare function for the german version of AUDI\'s Approved Used Car Locator.',
      tagline: [
        'Nunjucks',
        'SCSS',
        'Gulp',
        'Jeet',
        'Backbone.js',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'clapp.png',
      title: 'Clapp GmbH Website',
      date: '2014',
      client: 'clapp GmbH',
      project_url: 'http://clapp.de/',
      description: 'I built the company wordpress website for clapp GmbH.',
      tagline: [
        'Wordpress',
        'PHP',
        'SCSS',
        'CSS3',
        'HTML',
        'Twitter API',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'horizont.png',
      title: 'Horizont.net Relaunch & Newsletter',
      date: '2014',
      client: 'clapp GmbH',
      project_url: 'https://stefie.github.io/vr-wikipedia-heatmap/',
      description: 'I was part of the team for the frontend relaunch of HORIZONT.NET and I built the responsive newsletter templates.',
      tagline: ['HTML5', 'CSS3', 'JavaScript', 'jQuery']
    }, {
      image_1: 'ikiosk.png',
      title: 'iKiosk',
      date: '2014',
      client: 'clapp GmbH',
      project_url: 'https://stefie.github.io/vr-wikipedia-heatmap/',
      description: 'Technical Consulting during the design and concept process of the relaunch of Axel Springers Online News Stand.',
      tagline: ['Technical Consulting']
    }, {
      image_1: 'rpo.png',
      title: 'Rheinische Post iPad App',
      date: '2014',
      client: 'clapp GmbH',
      project_url: 'https://itunes.apple.com/de/app/rheinische-post/id902504255',
      description: 'I helped to build the HTML5 Frontend for the iPad App of the daily newspaper Rheinische Post.',
      tagline: ['HTML5', 'CSS3', 'Javavscript']
    }, {
      image_1: 'fr.png',
      title: 'Frankfurter Rundschau iPad App',
      date: '2014',
      client: 'clapp GmbH',
      project_url: 'https://itunes.apple.com/de/app/fr-plus/id834911051',
      description: 'I helped to build the HTML5 frontend for the iPad App of the daily newspaper Frankfurter Rundschau.',
      tagline: ['HTML5', 'CSS3', 'Javavscript']
    }, {
      image_1: 'gayfolksmovement.png',
      title: 'Gay Folks Movement',
      date: '2014',
      client: 'Aperto Plenum GmbH',
      description: 'This campaign website to support LGBTQ rights in Russia was a Aperto Plenum pro bono project. I did the Wordpress and frontend programming.',
      tagline: [
        'Wordpress',
        'PHP',
        'MySQL',
        'PHP MyAdmin',
        'HTML',
        'Scss',
        'JavaScript'
      ]
    }, {
      image_1: 'fitmio.png',
      title: 'Fitmio',
      date: '2013/2014',
      client: 'clapp Gmbh',
      description: 'I built the frontend for the fitness web app by the DuMont Schauberg publishing house.',
      tagline: [
        'JavaScript',
        'jQuery',
        'raphael.js',
        'HTML',
        'LESS',
        'Ajax',
        'JSON',
        'Bootstrap'
      ]
    }, {
      image_1: 'vw-xl1.png',
      title: 'VW XL1',
      date: '2013',
      client: 'Streampark e.V.',
      description: 'I joined the team as a frontend developer to build the product website for the Volkswagen hybrid 1-litre car XL1.',
      tagline: [
        'Drupal',
        'PHP',
        'MySQL',
        'SVN',
        'HTML',
        'CSS3',
        'JavaScript'
      ]
    }, {
      image_1: '1890.png',
      title: 'Allianz 1890 Magazine',
      date: '2013',
      client: 'Compucchino GmbH',
      project_url: 'https://1890.allianz.de/',
      description: 'I joined the team which built the online magazine for the insurance company Allianz for frontend tasks.',
      tagline: [
        'Drupal',
        'PHP',
        'MySQL',
        'HTML',
        'CSS3',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'wahl-de.png',
      title: 'Wahl.de',
      date: '2013',
      client: 'Compucchino GmbH',
      description: 'I worked on the migration to Drupal 7 & and supported the frontend relaunch of the data-driven german election & voting information platform wahl.de.',
      tagline: [
        'Drupal',
        'PHP',
        'MySQL',
        'HTML',
        'CSS3',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'mpass.png',
      title: 'Mpass - Product Website',
      date: '2012/2013',
      client: 'Compucchino GmbH',
      description: 'I built the product website for O2/Telefonica\'s mobile payment service Mpass as a Drupal Multisite.',
      tagline: [
        'Drupal Multisite',
        'PHP',
        'MySQL',
        'PHP MyAdmin',
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery',
        'Ajax'
      ]
    }, {
      image_1: 'postdigital.png',
      title: 'Postdigital Magazin',
      date: '2012',
      client: 'Aperto Plenum GmbH',
      description: 'I built the online version of Aperto Plenum\'s magazine "Postdigital" as a Wordpress Multisite.',
      tagline: [
        'Wordpress Multisite',
        'PHP',
        'MySQL',
        'JavaScript',
        'PHP MyAdmin',
        'HTML',
        'CSS',
        'JavaScript'
      ]
    }, {
      image_1: 'evl.png',
      title: 'EVL Community',
      date: '2012',
      client: 'Europäischer Verband Lifestyle',
      description: 'I integrated a Wordpress community section into the existing website.',
      tagline: [
        'Wordpress',
        'Buddypress',
        'PHP',
        'MySQL',
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'heimat-almanya.png',
      title: 'Heimat Almanya',
      date: '2011',
      client: 'Panorama 3000 GmbH',
      description: 'I was part of the team that built the website for the creative competition for young people by the integration commissioner of the federal government.',
      tagline: [
        'Drupal',
        'PHP',
        'MySQL',
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'yousingn.png',
      title: 'Chirurgiesuche',
      date: '2011',
      client: 'YouSign GmbH',
      project_url: 'http://www.chirurgie-suche.de/',
      description: 'I was part of the team that did the Drupal and frontend development.',
      tagline: [
        'Drupal',
        'PHP',
        'MySQL',
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'haglund.png',
      title: 'Haglund Industri',
      date: '2010',
      client: 'Haglund Industri',
      description: 'I built the Wordpress website for the swedisch manufacturer Haglund Industri.',
      tagline: [
        'Wordpress',
        'PHP',
        'MySQL',
        'PHP MyAdmin',
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery'
      ]
    }, {
      image_1: 'pianocity.png',
      title: 'Piano City Berlin',
      date: '2010',
      client: 'RADIALSYSTEM V, Andreas Kern',
      description: 'I build the event website for the three-day piano festival in Berlin.',
      tagline: [
        'Drupal',
        'PHP',
        'MySQL',
        'PHP MyAdmin',
        'HTML',
        'CSS',
        'jQuery',
        'YouTube API'
      ]
    }, {
      image_1: 'lyle-scott.png',
      title: 'Lyle & Scott Fashion Blog',
      date: '2010',
      client: 'THIS IS Studio',
      description: 'I built the Frontend for the fashion blog.',
      tagline: [
        'Wordpress',
        'PHP',
        'MySQL',
        'PHP MyAdmin',
        'HTML',
        'CSS',
        'jQuery'
      ]
    }
  ]
};

export {
  projectsData
};
