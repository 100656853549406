const cvData = {
  tags_recently: [
    'JavaScript',
    'TypeScript',
    'HTML5',
    'JSON',
    'SASS',
    
    'React',
    'Webpack',
    'JSS',
    'JSX',
    'RxJS',
    'Jest',
    'yarn',
    'npm',
    'WebAssembly',
    'wasm-pack',
    'AssemblyScript',
    'Node.js',
    'Cargo',
    'GitLab CI',
    'TypeDoc',
    'Docker',
    'Docker Compose',
    'Github Actions',
    'YAML',
    'Electron',
    '(Rust)'
  ],
  tags_formerly: [
    'Redux',
    'd3.js',
    'three.js',
    'A-Frame',
    'jQuery',
    'jQuery UI',
    'backbone.js',
    'raphael.js',
    'Gulp',
    'Grunt',
    'Babel',
    'Pug (Jade)',
    'Nunjucks',
    'Ajax',
    'underscore.js',
    'LESS',
    'Stylus',
    'Jeet',
    'OpenLayers',
    'GMaps JS API',
    'Leaflet',
    'Bootstrap',
    'Material UI',
    'Drupal',
    'Wordpress',
    'phpMyAdmin',
    'PHP',
    'XML',
    'MySQL',
    'SVN'
  ],
  tags_future: [
    'Rust', 'WebAssembly', 'Python', 'Data Visualization'
  ],
  tags_other: [
    'JIRA',
    'Confluence',
    'Notion',
    'Scrum ',
    'Kanban',
    'Photoshop',
    'Google Workspace',
    'Sketch',
    'Figma'
  ]
};

export {
  cvData
};
