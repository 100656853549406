import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import Navigation from './navigation';
import Templates from './routes';
import Footer from './footer';

export default class Website extends Component {
  render() {
    return (
    <div className={`page`}>
      <div className="sticky-nav">
        <header>
          <div className="page-header">
            <NavLink title="Home" exact="exact" to="/">
              <h1>Stefanie Doll</h1>
              <h2>Senior JavaScript Engineer</h2>
            </NavLink>
          </div>
          <Navigation/>
        </header>
      </div>
      <main id="container" className="page-content">
          <Templates />
      </main>
      <Footer/>
    </div>
    );
  }
}
