import React, { Component } from 'react';

export default class Contact extends Component {
    componentWillLeave(){
      console.log('componentWillLeave');
    }
  render() {
    return (
      <section className="error content-wrapper">
        <h2>4<span className="icon-stickman-8"></span>o4</h2>
        <p className="oversized">Not Found</p>
        <p>Sorry, the page you're looking for doesn't exist...</p>
      </section>
    );
  }
}
