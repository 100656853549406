import React, {Component} from 'react';
import Project from '../website/project';
import {projectsData} from './../../data/projects';

export default class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: projectsData.data
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    const projectList = this.state.projects.map((project, index) => {
      return <Project key={index} project={project}/>;
    });
    return (<section className="projects content-wrapper">
      <h2>What I've been working on</h2>
      <article className="project-wrapper">
        {projectList}
      </article>
    </section>);
  }
}
