import React, {Component} from 'react';
import {Link} from 'react-router-dom';

//import Timeline from './timeline';

export default class About extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (<div className="about">
      <section className="content-wrapper">
        <h2>Hi, thanks for dropping by!</h2>
        <p>My name is Stefanie and I am a Senior JavaScript Developer living in Berlin. After working in the industry for more than 10 years, I have acquired broad full-stack capabilities and I enjoy working with all stages of the development cycle of modern JavaScript apps and services. I'm always excited to learn about new technologies, explore new areas of application and to enhance my skills.</p>
        <p>Before the pandemic, I used to love to get new insights and stay up to date by visiting conferences and to extend my knowlege by visiting meetups and talking to fellow developers and I'm very much looking forward to do that again, soon.
        </p>
        <p>I'm a web developer by choice and with all my heart – because I love the web, the community around it and the concept of sharing knowledge and information across all devices and platforms.</p>
      </section>
      <section className="fullscreen-image">
        <div className="button-projects">
          <p> <Link to="/cv" target="_blank" rel="noreferrer" className="button">Have a look at my CV</Link></p>
          <p><Link to="/references" className="button">Check out my references</Link></p>
        </div>
      </section>
    </div>);
  }
}
