import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Navigation extends Component {
  render() {
    return (
       <nav className="page-nav">
         <ul>
           <li>
           <NavLink className="nav-link" to="/">
             <span className="icon icon-stickman-2"></span>
             <span className="brick">About</span>
           </NavLink>
          </li>
           <li>
           <NavLink className="nav-link" to="/references">
               <span className="brick">References</span>
               <span className="icon icon-stickman-5"></span>
           </NavLink>
           </li>
           <li>
           <NavLink className="nav-link" to="/contact">
               <span className="brick">Contact</span>
               <span className="icon icon-stickman-1"></span>
           </NavLink>
           </li>
         </ul>
       </nav>
    );
  }
}
