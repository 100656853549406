import React, {Component} from 'react';
import Timeline from './timeline';
import {cvData} from './../../data/cv';

export default class CV extends Component {
  render() {
    return (
      <section className="cv content-wrapper">
      <header>
        <section>
          <h1>Stefanie Doll</h1>
          <h2>Senior JavaScript Engineer</h2>
          <h4>
            <span className="fas fa-map-marker-alt"></span>Berlin, Germany</h4>
          <h4>
            <span className="far fa-envelope"></span>hello@stefaniedoll.de</h4>
        </section>
        <section>
          <ul className="contact-items-header">
            <li>
              <p>
                <span className="icon-bubbles"></span>
                <span>+49 1512 7570930</span>
              </p>
            </li>
            <li>
              <a href="https://stefaniedoll.de" title="My website" rel="noreferrer" target="_blank">
                <span className="icon-earth"></span>
                <span>wwww.stefaniedoll.de</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/Stefie" title="Find me on GitHub" rel="noreferrer" target="_blank">
                <span className="icon-github"></span>
                <span>https://github.com/Stefie</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/akaStefie" title="My twitter account" rel="noreferrer" target="_blank">
                <span className="icon-twitter"></span>
                <span>https://twitter.com/akaStefie</span>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/in/stefaniedoll" title="Visit me on LinkedIn" rel="noreferrer" target="_blank">
                <span className="icon-linkedin"></span>
                <span>https://linkedin.com/in/stefaniedoll</span>
              </a>
            </li>
          </ul>
        </section>
      </header>
      <section className="intro">
        <p>Hi, I'm Stefanie and I am a Senior JavaScript Developer based in Berlin. After working in the industry for more than 10 years, I have acquired broad full-stack capabilities and I enjoy working with all stages of the development cycle of modern JavaScript apps and services. I'm always excited to learn about new technologies, explore new areas of application and to enhance my skills.</p>
      </section>
      <section id="container" className="content">
        <section className="column column-twothird">
          <article>
            <h3>Selection of recent Positions & Projects</h3>
            <div className="textbox">
              <h4>Software Engineer JavaScript</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>12/2018 - 04/2021</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>
                  Parity Technologies GmbH, Berlin</p>
              </div>
              <p>I joined Parity Technologies to support the globally distrubuted team in building their decentralized network protocol Polkadot. I worked closely with different backend teams, DevOps and the UX team on a wide range of projects:</p>
              <ul>
                <li>Building different Proof of Concept UI's in React and contributing to existing frontend applications</li>
                <li>Implementing new features and applying changes happening in the Rust backend, optimizing test coverage with unit tests in Jest and writing documentation for the <a href="https://github.com/polkadot-js/api" title="GitHub Polkadot JS API" target="_blank" rel="noreferrer">PolkadotJS JavaScript API <span className="cv-link icon-new-tab"></span></a> written in TypeScript and RxJS
                </li>
                <li>Reverse engineering a set of WebAssembly Smart Contract modules written in Rust in order to be able to write a matching set of <a href="https://github.com/paritytech/as-substrate" title="GitHub Repo" target="_blank" rel="noreferrer">Wasm Smart Contracts in AssemblyScript <span className="cv-link icon-new-tab"></span></a> that could be seamlessly handled by the Smart Contract UI and executed by the Rust Framework</li>
                <li>Working closely with the DevOps team to optimize testing and building pipelines for various projects. The biggest one of them was a <a href="https://github.com/paritytech/pallet-contracts-waterfall" title="GitHub Substrate Connect" target="_blank" rel="noreferrer">Smart Contract waterfall testing suite <span className="cv-link icon-new-tab"></span></a> to run integration tests for all relevant components in the Substrate Smart Contract ecosystem.</li>
                <li>Researching the possibilities of and defining the requirements for bundling Wasm blockchain modules as a Node module and running them in a browser environment and browser extensions for <a href="https://github.com/paritytech/substrate-connect" title="GitHub Substrate Connect" target="_blank" rel="noreferrer">Substrate Connect <span className="cv-link icon-new-tab"></span></a> together with building a first prototype for the Node module and a UI utilizing it</li>
              </ul>
              <p>My work also included product and project planning, setting goals and expectations for the projects I was working on, researching potential use-cases and their technological requirements as well as writing code documentation and interviewing, hiring and onboarding new team members.</p>
              <p className="project-tags">
                <span className="technologies fas fa-keyboard"></span>JavaScript, TypeScript, Node.js, WebAssembly, AssemblyScript, React, RxJS, Jest, JSON,<br />TypeDoc, Docker Compose, Yaml, GitLab CI, Github Actions, wasm-pack, Cargo, (Rust)</p>
            </div>
            <div className="textbox">
              <h5>I have been self-employed for 8 years and I worked with multiple clients from 2010-2018. This is just a small selection of projects. Please visit <a href="https://stefaniedoll.de/references" title="Stefanie Doll - Senior JavaScript Engineer" target="_blank" rel="noreferrer">https://stefaniedoll.de/references <span className="cv-link icon-new-tab"></span></a> for an extensive list.</h5>
             </div>
            <div className="textbox">
              <h4>Senior Frontend Engineer</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>08/2017 - 06/2018</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>Planet Labs Germany, Berlin</p>
              </div>
              <p>I joined the Software Engineering team of Planet Labs Germany to work on the React/Redux frontend of different internal administrative geospatial Web Applications. They were used to task the companies fleet of nanosatellites, assess and monitor collected satellite images and to manage user accounts and their access to imagery data.
              </p>
              <p className="project-tags">
                <span className="technologies fas fa-keyboard"></span>React, Redux, Jest, ES6, D3, OpenLayers, Leaflet, Axios, Yarn, Material-UI</p>
            </div>
            <div className="textbox">
              <h4>Frontend Engineer</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>01/2016 - 08/2016</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>
                  interactive tools GmbH, Berlin</p>
              </div>
              <p>
                I worked on multiple projects, including the frontend of the e-magazine for the German statutory health insurance funds (GKV) and website of the federal business development bank of Berlin (IBB).
              </p>
              <p className="project-tags">
                <span className="technologies fas fa-keyboard"></span>JavaScript, jQuery, React, HTML5, CSS3, Less, Grunt, Google Maps, Bootstrap, npm</p>
            </div>
            <div className="textbox">
              <h4>Frontend Engineer</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>05/2015 - 12/2015</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>Immobilien Scout GmbH, Berlin</p>
              </div>
              <p>I was responsible for the frontend development for the editorial CMS content and worked closely with the UX-Team while building the templates for the search app of the Austrian property search platform.
              </p>
              <p className="project-tags">
                <span className="technologies fas fa-keyboard"></span>Jade, Stylus, CSS3, gulp, JavaScript, npm, UX Consulting
              </p>
            </div>
          </article>
        </section>

        <aside className="column column-third">
          <h3>Technologies</h3>
          <h4>Recently used:</h4>
          <ul className="tagline">
            {
              cvData.tags_recently.map((tag, i) => {
                return <li key={i}>{tag}</li>;
              })
            }
          </ul>
          <h4>Formerly used:</h4>
          <ul className="tagline">
            {
              cvData.tags_formerly.map((tag, i) => {
                return <li key={i}>{tag}</li>;
              })
            }
          </ul>
          <h4>Interested in learning:</h4>
          <ul className="tagline">
            {
              cvData.tags_future.map((tag, i) => {
                return <li key={i}>{tag}</li>;
              })
            }
          </ul>
          <h3>Productivity & Design</h3>
          <ul className="tagline">
            {
              cvData.tags_other.map((tag, i) => {
                return <li key={i}>{tag}</li>;
              })
            }
          </ul>
        </aside>

      </section>
      <div className="page-break"></div>
      <section className="content">
        <section className="column column-twothird">
          <article>
            <h3>Engagement & Open Source Projects</h3>
            <div className="textbox">
              <h4>Organizer & Tutor of A-Frame Beginners Workshop</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>10/08/2017</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>WebXR Berlin Meetup – Mozilla, Berlin</p>
              </div>
            </div>
            <div className="textbox">
              <h4>Talk: Bringing Virtual Reality to the Web with WebVR and A-Frame</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>25.04.2017</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>Women Who Code Berlin Meetup – NewStore GmbH, Berlin</p>
              </div>
            </div>
            <div className="textbox textbox-inline">
              <h4>Supporting member of Wikimedia Germany e.V.</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>since 2014</p>
              </div>
            </div>
            <div className="textbox">
              <h4>Co-organizer of Atoms&Bits – Festival of thinking, making and doing</h4>
              <div className="information">
                <p>
                  <span className="fas fa-calendar-alt"></span>18/09/2009 – 27/09/2009</p>
                <p>
                  <span className="fas fa-map-marker-alt"></span>Berlin</p>
              </div>
              <p>Decentralized festival and camp about how the spirit of open source communities and online collaborations change society, connected through the internet with real-world results. With tracks about arts, politics, DIY culture & Maker Labs, Coworking and Open Everything.</p>
            </div>
          </article>
        </section>
        <aside className="column column-third">
          <h3>Languages</h3>
          <p>German – native</p>
          <p>English – fluent</p>
        </aside>
      </section>
      <section className="column">
        <Timeline/>
      </section>
      <footer>
        <p>Stefanie Doll – Senior JavaScript Engineer – Berlin, Germany – hello@stefaniedoll.de</p>
      </footer>
    </section>);
  }
}
