import React from 'react';

const Timeline = () => (<section className="timeline">
  <h3>Timeline Education & Professional Experience</h3>
  <div className="timeline-box">
    <div className="timestamp">09/2007</div>
    <div className="textbox">
      <h4>M.A. Mass Communication Research, Political Science and Economic&Social Psychology
      </h4>
      <div className="information">
        <p>
          <span className="fas fa-map-marker-alt"></span>Georg-August-Universität, Göttingen, Germany</p>
      </div>
      <p>I have a Masters degree (M.A.) in Mass Communication Research, Business & Social Psychology and Politics (Grade A). During my studies, I strongly focussed on online activism, political mass communication and 'new media'. While studying the social and political impact of the internet and the rapidly changing media landscape, I got more and and more interested in and fascinated by the technology that is running the internet, the World Wide Web and open source communities.</p>
      <p>I wrote my master thesis about "Online-Demonstrations and DDoS attacks as a new type of political participation".</p>
    </div>
  </div>
  <div className="timeline-box">
    <div className="timestamp">04/2008 - 12/2008</div>
    <div className="textbox">
      <h4>Traineeship: Information Architecture & Community Management</h4>
      <div className="information">
        <p>
          <span className="fas fa-map-marker-alt"></span>hiogi GmbH, Berlin, Germany</p>
      </div>
      <p>After I finished my studies, I joined a Q&A Start-Up as a Community Manager and Information Architect to develop new strategies and features to encourage user engagement and strengthen commitment within the online community. That's when I realized, that I want to learn how to code, that I'd rather build and program websites than being an online content creator and community manager.</p>
    </div>
  </div>
  <div className="timeline-box">
    <div className="timestamp">03/2009 - 01/2010</div>
    <div className="textbox">
      <h4>Certified Training: Web-Development/ Web-Administration</h4>
      <div className="information">
        <p>
          <span className="fas fa-map-marker-alt"></span>cimdata Bildungsakademie, Berlin, Germany</p>
      </div>
      <p>For the following year, I took part in a full-time post graduate training in web programming and web administration. I finished courses in CSS, HTML, JavaScript, Ajax, PHP, MySQL, Photoshop, Maya 3D and Dreamweaver and worked on several smaller projects to train my skills.</p>
    </div>
  </div>
  <div className="timeline-box">
    <div className="timestamp">04/2010 - 2014</div>
    <div className="textbox">
      <h4>Full-Stack Drupal & Wordpress Development</h4>
      <div className="information">
        <p>
          <span className="fas fa-map-marker-alt"></span>Self-emloyed, multiple clients, Berlin, Germany</p>
      </div>
      <p>With that knowledge, I started my career as a freelance full-stack Drupal and Wordpress Developer. I provided the full service from server setup over PHP module & template creation to frontend development.<br />My clients were mainly digital agencies in Berlin.</p>
    </div>
  </div>
  <div className="timeline-box">
    <div className="timestamp">2014-2018</div>
    <div className="textbox">
      <h4>JavaScript & Frontend Engineer</h4>
      <div className="information">
        <p>
          <span className="fas fa-map-marker-alt"></span>Self-emloyed, multiple clients, Berlin, Germany</p>
      </div>
      <p>In 2014 I decided that it's time to concentrate on either frontend or backend development. I quit CMS programming and working with PHP and MySQL to be able to focus on advancing my skills in JavaScript, JavaScript frameworks & libraries and other relevant frontend technologies.</p>
    </div>
  </div>
  <div className="timeline-box">
    <div className="timestamp">12/2018 - 04/2021</div>
    <div className="textbox">
      <h4>Sofware Engineer JavaScript</h4>
      <div className="information">
        <p>
          <span className="fas fa-map-marker-alt"></span>Parity Technologies GmbH, Berlin, Germany</p>
      </div>
      <p>In late 2018 I joined Parity Technologies as a full-time  Software Engineer JavaScript to support the team in building their decentralized network protocol <a href="https://polkadot.network/" target="_blank" rel="noreferrer" title="Go to website">Polkadot <span className="cv-link icon-new-tab"></span></a> and to work on a variety of tasks in the development process of the Blockchain Framework <a href="https://substrate.io/" target="_blank" rel="noreferrer" title="Go to website">Substrate <span className="cv-link icon-new-tab"></span></a>.</p>
      <p>I was driven by the possibilities to work with emerging languages like WebAssembly and Rust, to learn TypeScript and to get more insights into lower level use-cases of JavaScript like JS API development, working with Node.js and creating Node modules, while still being able to use the skills I have acquired over the past decade.</p>
    </div>
  </div>
</section>);

export default Timeline;
