import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import "typeface-roboto-mono";
import "typeface-roboto";
import "typeface-rubik";
import "@fortawesome/fontawesome-free/css/all.css";

import App from './App';

ReactDOM.render((<BrowserRouter>
  <App/>
</BrowserRouter>), document.getElementById('root'));
