import React, {Component }from 'react';
import { Routes, Route } from 'react-router-dom';

import About from './about';
import Contact from './contact';
import Imprint from './imprint';
import ProjectList from './projectList';
import CV from '../cv/cv';
import Error404 from './404';

export default class Templates extends Component {
  render() {
    return (
      <Routes>
          <Route index path="/" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/references" element={<ProjectList />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/cv" element={<CV />} /> 
          <Route path="*" element={<Error404 />} />
      </Routes>
    );
  }
}

