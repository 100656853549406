import React, { Component } from 'react';

export default class Contact extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <section className="contact content-wrapper">
        <div className="contact-wrapper">
          <h2>Get in touch</h2>
          <p>If you have any questions or just want to get in touch, feel free to contact me.</p>
          <h3>Find me on:</h3>
          <ul className="contact-list">
            <li><a href="https://www.linkedin.com/in/stefaniedoll/" title="Visit me on LinkedIn" target="blank"><span className="icon-linkedin"></span><span>LinkedIn</span></a></li>
            <li><a href="https://twitter.com/akaStefie" title="My twitter account" target="blank"><span className="icon-twitter"></span><span>Twitter</span></a></li>
            <li><a href="https://github.com/Stefie" title="Find me on GitHub" target="blank"><span className="icon-github"></span><span>GitHub</span></a></li>
          </ul>
          <p>Or send me an email to: hello[at]stefaniedoll.de</p>
        </div>
      </section>
    );
  }
}
