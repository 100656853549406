import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';

export default class Project extends Component {
  render() {
    const { project } = this.props;
    const defaultImage = require('./../../assets/images/screens-low_res/' + project.image_1);
    return (
      <div className="project">
        <div className="project-details">
          <div className={`project-image ${ project.image_2 ? "animated" : '' }`}>
            <ProgressiveImage
              src={require('./../../assets/images/screens/' + project.image_1)}
              placeholder={defaultImage}>
              {(src) => <img src={src} alt={`Screen ${project.title}`}/>}
            </ProgressiveImage>
            { project.image_2 &&
              <ProgressiveImage
                src={require('./../../assets/images/screens/' + project.image_2)}
                placeholder={require('./../../assets/images/screens-low_res/' + project.image_2)}>
                {(src) => <img src={src} alt={`Screen 2 ${project.title}`}/>}
              </ProgressiveImage>
            }
          </div>
          <div className="project-text">
            <h4>{project.title}</h4>
            <p className="project-info">{project.date}</p>
            <p dangerouslySetInnerHTML={ {__html: project.description} } />
          </div>
        </div>
        <div className="project-footer">
          {project.client &&
            <div className="project-highlight">
              <p>Client: {project.client}</p>
            </div>
          }
          <ul className="tagline">
            {project.tagline.map((tag, i) => {
                return <li key={i} >{tag}</li>;
              })
            }
          </ul>
          <div>
            {project.project_url &&
              <a href={project.project_url} className="button" title="visit website on {project.project_url}" target="_blank"  rel="noreferrer">
                Website
              </a>
            }
            {project.github_url &&
              <a href={project.github_url} className="button" title="view project on GitHub" target="_blank"  rel="noreferrer">
                Github
              </a>
            }
          </div>
        </div>
      </div>
    );
  }
}
