import React, { Component }from 'react';
import { Route, Routes } from 'react-router-dom';

import Website from './components/website/index';
import CV from './components/cv/cv';
import './assets/scss/app.scss';

export default class App extends Component {
  render() {
    return (
      <Routes>
          <Route exact="exact" path="/cv" element={<CV />} />
          <Route index path="*" element={<Website />} />
      </Routes>
    );
  }
}